@import "../../styles/variables.scss";

.PaymentError {
  padding: $content-padding;

  .error {
    padding: 1rem;
    border: 1px solid  rgb(226, 196, 200);
    background-color: rgb(246, 212, 213);
    color:rgb(144, 51, 53);

    @include border-radius(5px);
  }

  .buttons {
    margin: 3rem 0;
    text-align: center;

    a {
      width: 100%;
      margin-bottom: 2rem;
      display: block;
    }

    button {
      width: 100%;
      max-width: 20rem;
      background-color: $green;
      color: white;

      padding: 0 1rem;
      box-sizing: border-box;
      font-weight: bold;

      &.back {
        background-color: $green;
        color: white;
        border-bottom: 1rem;
      }

      &.home {
        background-color: $blue;
        color: white;
      }
    }

    @media (min-width: $mobile-width) {
      display: flex;
    }
  }
}
