.ContactUs {
  margin: 2rem;

  h2 {
    margin-top: 2rem;
  }

  p {
    line-height: 2rem;
  }

  a {
    color: blue;
  }
}
