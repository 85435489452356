.apple-pay-button {
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-style: black;
  height: 45px;
  margin-top: 1rem;
  width: 100%;
  // -apple-pay-button-type: check-out;
  font-size: 1rem;
  color: pink;

  &:hover {
    cursor: pointer;
  }
}
