@import '~normalize.css';

/* reset css and base app styles */

html {
  font-size: 100%;
  background-color: rgb(25, 34, 59);
}

body {
  font-size: 1rem;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p, h1, h2, h3, h4 {
  padding: 0;
  margin: 0;
}

p {
  margin: 1rem 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
