@import "~styles/variables.scss";

#App {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;

  nav {
    width: 100%;
    height: $nav-height;
    padding: 0 2rem;
    color: white;
    background-color: $nav-background;
    border-bottom: 1px solid black;

    position: fixed;
    box-sizing: border-box;
    z-index: 2;
    top: 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;


    .title {
      margin-top: 6px;
      padding-right: 2rem;
      border-right: 1px solid rgba(255, 255, 255, 0.1);

      @media (max-width: $mobile-width) {
        border-right: none;
      }
    }

    ul {
      color: white;
      display: flex;
      flex-direction: row;

      li {
        a {
          display: inline-block;
          color: white;
          line-height: $nav-height;
          padding: 0 20px;

          &:hover {
            background-color: $nav-item-hover;
          }
        }
      }

      @media (max-width: $mobile-width) {
        display: none;
      }
    }
  }

  main {
    margin-top: $nav-height;
    background-color: white;
    flex-grow: 1;
  }

  footer {
    color: white;
    padding: 1.5rem 0;
    border-top: 1px solid black;
    background-color: $nav-background;
    text-align: center;

    div {
      display: flex;
      justify-content: space-evenly;
    }
  }

  button {
    height: 3rem;
    min-width: 6rem;
    text-align: center;
    border: none;
    border-radius: 5px;
    line-height: 3rem;

    &:hover {
      cursor: pointer;
      box-shadow: -1px 1px 4px 0 rgb(150, 150, 150);
    }

    &:disabled {
      background-color: $button-disabled;

      &:hover {
        cursor: initial;
        box-shadow: none;
      }
    }
  }
}
