@import "../../styles/variables.scss";

.Home {
  .intro {
    position: relative;
    overflow: hidden;
    background-color: $dark-blue;
    color: white;

    .container {
      width: $content-width;
      margin: $spacing auto;
    }

    .hex-1 {
      top: -4rem;
      left: -7rem;
    }

    .hex-2 {
      top: 3rem;
      right: -7rem;
    }

    @media (max-width: $mobile-width) {
      h1 {
        font-size: 1.75rem;
      }
    }
  }

  .cols {
    width: $content-width;
    margin: 4rem auto;

    display: flex;
    justify-content: space-between;

    @media (max-width: $mobile-width) {
      margin: 2rem auto;
      flex-direction: column;
      align-items: center;
      width: 82vw;
    }

    .col {
      padding: 1rem;
      width: 20vw;
      box-sizing: border-box;

      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;

      box-shadow: -1px 1px 4px 0 rgb(150, 150, 150);

      &:not(:last-child) {
        margin-right: $spacing;
      }

      @media (max-width: $mobile-width) {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 3rem;
          margin-right: 0;
        }
      }

      .view-merchants {
        background-color: $green;
        color: white;
        width: 100%;
        margin-top: 1rem;
      }
    }
  }

  .outro {
    .container {
      width: $content-width;
      margin: $spacing auto;

      p {
        margin-bottom: 4rem;
      }
    }
  }
}
