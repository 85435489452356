@import "../../styles/variables.scss";

.Merchants {
  width: $content-width;
  margin: 0 auto;

  @media (max-width: $mobile-width) {
    width: 90vw;
  }

  > h1 {
    margin: 2rem 0;
  }

  .merchant-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 2rem;

    .merchant {
      margin-top: 2rem;
      border-radius: 5px;
      text-align: center;
      padding: 1rem;
      box-shadow: 0 0px 10px rgb(17 124 212 / 25%);
      transition: box-shadow 0.5s;

      &:hover {
        cursor: pointer;
        box-shadow: 0 0px 10px rgb(17 124 212 / 50%);
        transition: box-shadow 0.5s;
      }

      h3 {
        margin-bottom: 1rem;
      }

      img {
        width: 300px;
      }
    }

    @media (max-width: $mobile-width) {
      .merchant {
        width: 90%;

        img {
          width: 100%;
        }
      }
    }
  }
}
