@import "../../styles/variables.scss";

.Merchant {
  width: fit-content; // $content-width;
  margin: 0 auto;
  // testing
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .content {
    > * + * {
      margin-top: 1rem;
    }

    h1 {
      margin: 2rem 0;

      @media (max-width: $mobile-width) {
        margin: 1rem 0;
      }
    }

    .products {
      .product {
        max-width: 20rem;

        img {
          width: 100%;
          margin-top: 1rem;
        }

        .product-count {
          display: flex;
          font-weight: bold;
          font-size: 2rem;
          margin-top: 1rem;

          button {
            background-color: $blue;
            color: white;
          }

          .count {
            height: 3rem;
            width: 6rem;
            text-align: center;
            line-height: 3rem;
            border: 1px solid #ebebeb;
            border-radius: 5px;
            margin: 0 1rem;
          }
        }
      }
    }

    form {
      margin-top: 2rem;
    }
  }

  form {
    margin-top: 2rem;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: 0 solid black;
  }

  .field-wrapper {
    height: 30px;
  }

  .fake-field {
    align-items: center;
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid transparent;
    box-shadow: 0 1px 3px 0 #999;
    display: flex;
    justify-content: space-between;
    height: 42px;
    margin-bottom: 10px;
    width: 20rem;

    > span {
      height: 100%;
      padding-left: 10px;
    }

    #brand {
      background-color: #333;
      border-radius: 3px;
      color: #fff;
      display: block;
      font-family: arial;
      font-size: 14px;
      margin-left: 5px;
      overflow: hidden;
      padding: 1px 3px;
      text-align: center;
      width: 69px;
      height: 30px;
      line-height: 30px;
      display: none;
    }
  }

  .buttons {
    margin: 3rem 0;

    @media (max-width: $mobile-width) {
      margin: 2rem 0;
    }

    .GooglePayButton {
      margin-top: 1rem;

      button {
        background-color: white !important;
        padding: 0 !important;

        .gpay-card-info-animation-container {
          height: 3rem;
          line-height: 3rem;

          .gpay-card-info-placeholder-container {
            .gpay-card-info-animation-gpay-logo {
              margin: 18px 10px 0px 53px;
            }

            svg {
              margin-top: 5px;
            }
          }
        }
      }
    }

    button {
      width: 100%;
      max-width: 20rem;
      background-color: $green;
      color: white;

      display: flex;
      justify-content: space-between;
      padding: 0 1rem;
      box-sizing: border-box;
      font-weight: bold;

      &.checkout {
        background-color: $green;
        color: white;
      }

      &.purchase {
        letter-spacing: 1px;
        background-color: $green;
        color: white;
      }

      &.back {
        margin-top: 1rem;
        background-color: #19223b;
        color: white;
      }
    }
  }
}
