.TermsAndService {
  margin: 2rem;

  .upperCase {
    text-transform: uppercase;
  }

  .italic {
    font-style: italic;
  }

  .bold {
    font-weight: bold;
  }

  h2 {
    margin-top: 2rem;
  }

  p {
    line-height: 2rem;
  }

  a {
    color: blue;
    text-decoration: underline;
  }
}

.PrivacyNotice {
  margin: 2rem;

  .upperCase {
    text-transform: uppercase;
  }

  .bold {
    font-weight: bold;
  }

  .underline {
    text-decoration: underline;
  }

  .italic {
    font-style: italic;
  }

  ol li {
    display: list-item;
    list-style-type: decimal;
    margin-left: 30px;
  }

  ul li {
    display: list-item;
    list-style-type: circle;
    margin-left: 30px;
  }

  a {
    color: blue;
    text-decoration: underline;
  }
}
