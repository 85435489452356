/* variables */
$nav-height: 4rem;
$mobile-width: 800px;
$content-width: 75vw;
$content-padding: 3rem;
$spacing: 2rem;
$nav-background: rgb(25, 34, 59);
$nav-item-hover: rgb(46, 60, 99);
$dark-blue: #05028F;
$blue: #2A67FF;
$green: #52a452;
$button-disabled: rgb(200, 200, 200);

/* mixins */
@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  background-clip: padding-box;
}
