@import "../../styles/variables.scss";

.PaymentSuccess {
  padding: $content-padding;

  .description {
    font-weight: bold;
  }

  .success {
    padding: 1rem;
    border: 1px solid  rgb(174, 189, 167);
    background-color: rgb(214, 238, 207);
    color:rgb(50, 100, 47);
    overflow-wrap: break-word;

    @include border-radius(5px);
  }

  .buttons {
    margin: 3rem 0;
    text-align: center;

    a {
      width: 100%;
      margin-bottom: 2rem;
      display: block;
    }

    button {
      width: 100%;
      max-width: 20rem;
      background-color: $green;
      color: white;

      padding: 0 1rem;
      box-sizing: border-box;
      font-weight: bold;

      &.back {
        background-color: $green;
        color: white;
        border-bottom: 1rem;
      }

      &.home {
        background-color: $blue;
        color: white;
      }
    }

    @media (min-width: $mobile-width) {
      display: flex;
    }
  }
}
